@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-Medium.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Quicksand";
  src: url("./fonts/Quicksand-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

::-webkit-input-placeholder {
  font-family: 'Quicksand',sans-serif;
}
:-moz-placeholder {
  font-family: 'Quicksand',sans-serif;
}
::-moz-placeholder {
  font-family: 'Quicksand',sans-serif;
}
:-ms-input-placeholder {
  font-family: 'Quicksand',sans-serif;
}

select, option {
  font-family: 'Quicksand',sans-serif;
}

@media only screen and (max-width: 700px) {
  .plugin .plugin-name {
    width: 100%;
  }
}

.App {
  max-width: 1200px;
  margin: auto;
  color: #313b4a;
}

.App-logobar {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.App-logo {
  height: 35px;
}

.App-official-icon {
  height: calc(8px + 2vmin);
  margin-right: 5px;
}

.App-header {
  font-size: calc(12px + 1vmin);
  margin: 1rem;
}

.App-searchbar {

}

h1 {
  margin: 1rem;
}

h2 {
  font-size: 2rem;
  color: #44b492;
  font-weight: bold;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

a {
  text-decoration: none;
  color: #1a76bf;
}

a:hover {
  color: #44b492;
}

.plugin-search-input {
  width: 100%;
  height: 2em;
  font-size: calc(12px + 1vmin);
  margin-bottom: 1rem;
  box-sizing: border-box;
  border-radius: 4px;
  font-family: 'Quicksand',sans-serif;
}

ul {
  padding: 0;
  margin: 0;
}

ul li {
  list-style: none;
}

.plugin-list {
  margin: 0 1rem;
}

.plugin {
  text-align: left;
  background-color: #f0f2f2;
  color: rgba(20,20,20,0.8);
  border: 1px solid #282c34;
  margin-bottom: 1em;
  padding: 1rem;
  border-radius: 4px;
}

.plugin .plugin-headline {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
}

.plugin .plugin-name {
  text-align: left;
  display: block;
  font-weight: bold;
  font-size: calc(14px + 2vmin);
  margin-right: 8px;
}

.plugin-name a {
  color: #44b492;
}

.plugin-list-sort {
  float: right;
}

.plugin .plugin-version {
  text-align: left;
  flex: auto;
  font-family: 'Consolas','Monaco','Lucida Console','Liberation Mono','DejaVu Sans Mono','Bitstream Vera Sans Mono','Courier New',sans-serif;
}

.plugin .plugin-time {
  text-align: right;
}

.plugin-downloads {
  width: 40px;
  height: 10px;
  margin: 0.5em;
}

.plugin .plugin-footer {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  align-items: baseline;
}

.plugin .plugin-author {
  margin-right: 1em;
}

.plugin .plugin-npm-link {
  flex: auto;
  font-size: calc(12px + 2vmin);
  font-weight: bold;
  color: black;
  -webkit-text-fill-color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: red;
}

.plugin .plugin-keywords {
  text-align: right;
  display: flex;
  flex-wrap: wrap;
}

.plugin .plugin-keyword {
  text-align: center;
  font-size: calc(8px + 0.5vmin);
  border-radius: 5px;
  border: darkgray solid 1px;
  background-color: rgba(0, 0, 0, 0.2);
  margin-left: 2px;
  margin-right: 2px;
  padding: 3px;
}